import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { Station } from '../../../types/station';
import { useStationForm } from '../../../hooks/Stations/useStationForm';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import { CustomAutoComplete } from '../../_shared/CustomComponents';
import { InputTextarea } from 'primereact/inputtextarea';

interface StationDialogProps {
  visible: boolean;
  setStationDialogVisible: (b: boolean) => void;
  onStationAdded?: (station: Station) => void;
  station: Station | null;
  locations: any[] | null;
  customers: any[] | null;
}

export const StationDialog = ({
  visible,
  setStationDialogVisible,
  onStationAdded,
  station,
  locations,
  customers,
}: StationDialogProps) => {
  const { formik, hideDialog } = useStationForm(station, onStationAdded, setStationDialogVisible);

  const stationDialogFooter = (
    <>
      <Button type="reset" label={'Anuluj'} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button type="submit" label={'Zapisz'} icon="pi pi-check" className="p-button-text" onClick={formik.submitForm} />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={'Szczegóły'}
        modal
        className="p-fluid"
        footer={stationDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="p-field">
            <label htmlFor="name">{'Nazwa'}</label>
            <InputText id="name" value={formik.values.name} onChange={formik.handleChange} autoFocus />
            <FormErrorMessage fieldName="name" formikInstance={formik} />
          </div>
          <div className="p-field">
            <label htmlFor="installationId">{'Identyfikator instalacji'}</label>
            <InputText id="installationId" value={formik.values.installationId!} onChange={formik.handleChange} />
            <FormErrorMessage fieldName="installationId" formikInstance={formik} />
          </div>
          <div className="p-field">
            <label htmlFor="customer">{'Klient'}</label>
            <CustomAutoComplete
              id="customer"
              value={customers?.find((c) => c.id === formik.values.customerId) ?? formik.values.customerId}
              handler={(e: any) => {
                void formik.setFieldValue('customerId', e.value?.id ?? e.target?.value?.id ?? e.target?.value);
              }}
              source={customers}
              lookupField={'companyName'}
              inputStyle={undefined}
              customSearchBranch={undefined}
              customItemTemplate={undefined}
              placeholder={undefined}
              scrollHeight={undefined}
              tooltip={undefined}
              disabled={undefined}
              forceSelection={undefined}
              onBlur={undefined}
            />
            <FormErrorMessage fieldName="customer" formikInstance={formik} withTouchedTrue />
          </div>
          <div className="p-field">
            <label htmlFor="location">{'Oddział'}</label>
            <CustomAutoComplete
              id="location"
              value={locations?.find((l) => l.id === formik.values.locationId) ?? formik.values.locationId}
              handler={(e: any) => {
                void formik.setFieldValue(
                  'locationId',
                  e.value?.id ?? e.target?.value?.id ?? e.target?.value ?? formik.values.locationId,
                );
              }}
              source={locations?.filter((l) =>
                formik.values.customerId ? l.customer?.id === formik.values.customerId : true,
              )}
              lookupField={'name'}
              inputStyle={undefined}
              customSearchBranch={undefined}
              customItemTemplate={undefined}
              placeholder={undefined}
              scrollHeight={undefined}
              tooltip={undefined}
              disabled={undefined}
              forceSelection={undefined}
              onBlur={undefined}
            />
          </div>
          <div className="p-field">
            <label htmlFor="vpnAddress">Adresacja VPN</label>
            <InputText id="vpnAddress" value={formik.values.vpnAddress} onChange={formik.handleChange} />
            <FormErrorMessage fieldName="vpnAddress" formikInstance={formik} />
          </div>
          <div className="p-field">
            <label htmlFor="remarks">Uwagi</label>
            <InputTextarea
              autoResize
              id="remarks"
              value={formik.values.remarks}
              onChange={(e) => formik.handleChange(e)}
            />
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};
