import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { Station } from '../../../types/station';
import stationsService from '../../../services/ScaleoAdminApiServices/StationsService';
import { handleClickCopy } from '../../../utils/copy';

interface GenerateAnprApiKeyDialogProps {
  visible: boolean;
  closeDialog: (b: boolean) => void;
  station: Station;
}

const GenerateAnprApiKeyDialog = ({ station, visible, closeDialog }: GenerateAnprApiKeyDialogProps) => {
  const [anprApiKey, setAnprApiKey] = useState<string | null>(null);
  const [isCopiedAnprApiKey, setIsCopiedAnprApiKey] = useState(false);

  useEffect(() => {
    setAnprApiKey(station?.anprApiKey ?? null);
  }, [JSON.stringify(station)]);

  if (!station) {
    return <></>;
  }

  const generateAnprApiKey = async () => {
    const anprApiKeyResponse = await stationsService.generateAnprApiKey(station.id!);

    setAnprApiKey(anprApiKeyResponse.data);
  };

  const dialogFooter = (
    <>
      <Button
        type="reset"
        label="Anuluj"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => closeDialog(true)}
      />
    </>
  );

  return (
    station && (
      <div className="customer-edit-dialog">
        <Dialog
          visible={visible}
          header="Szczegóły klienta"
          modal
          className="p-fluid"
          breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
          style={{ width: '40vw' }}
          footer={dialogFooter}
          onHide={() => closeDialog(true)}
        >
          <Button
            className="p-button-info my-split-button"
            onClick={() => generateAnprApiKey()}
            style={{ width: '20vw' }}
            disabled={!!anprApiKey}
          >
            {' '}
            Generuj klucz API ANPR{' '}
          </Button>

          <div className="p-field" style={{ marginTop: '5vh' }}>
            <label htmlFor="anprApiKey">Klucz API ANPR</label>
            <InputText id="anprApiKey" value={anprApiKey ?? ''} disabled />
            <div style={{ display: 'inline-block' }}>
              <Button
                className="p-button-info my-split-button"
                style={{ width: '10vw', marginBottom: isCopiedAnprApiKey ? '1vh' : '5vh', marginTop: '2vh' }}
                onClick={() => handleClickCopy(anprApiKey!, setIsCopiedAnprApiKey)}
                disabled={isCopiedAnprApiKey || !anprApiKey}
              >
                Kopiuj
              </Button>
              {isCopiedAnprApiKey && (
                <Message style={{ height: '4vh', width: '20vw' }} text={'Skopiowano do schowka'} />
              )}
            </div>
          </div>
        </Dialog>
      </div>
    )
  );
};

export default GenerateAnprApiKeyDialog;
